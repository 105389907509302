import orderPayments from "./payments";

const localNumberKey = 'local_number';

const calculateDiscount = (value, price) => {
    return Number(Number((Number(value) * Number(price)).toFixed(2)) / 100);
};

const medical = {
    code: 'AQC01',
    name: 'A QUIEN CORRESPONDA'
};

export default {
    namespaced: true,
    modules: {
        orderPayments
    },
    state: {
        patient: {},
        studies: [],
        company: {},
        medical: {},
        discountLab: 0,
        discountGab: 0,
        paid: false,
        order_new: null,
        payInCash: true,
        coPay: false,
        coPayCustomPercent: 0,
        agreement: false,
        localNumberKey: localStorage.getItem(localNumberKey) || '',
        priceLoader: false,
        diagnostics: [{}, {}],
        subBranchOffice: 0,
        foreignWork: {},
        foreignWorkCheck: false,
        foreignWorkName: '',
        withIva: true,
        medicalCode: '',
        patientSign: ''
    },
    mutations: {
        setPatient(state, val = {}) {
            state.patient = val;
        },
        setLocalNumber(state, val = 0) {
            state.localNumber = val;
            localStorage.setItem(localNumberKey, val);
        },
        setCompany(state, val = {}) {
            state.company = val;
            state.discountGab = 0;
            state.discountLab = 0;
            state.coPay = false;
            state.agreement = false;
            if (!!val.company) {
                state.payInCash = val.pay_in_cash
            } else {
                state.payInCash = true
            }
        },
        setPayInCash(state, val = false) {
            state.payInCash = val;
        },
        setCopay(state, val = false) {
            state.coPay = val;
        },
        setAgreement(state, val = false) {
            state.agreement = val;
        },
        setMedical(state, val = {}) {
            state.medical = val;
        },
        setDefaultMedical(state) {
            state.medical = JSON.parse(localStorage.getItem('medical')) || medical;
        },
        setStudies(state, val = []) {
            //  console.log('salvando estudios', val);
            state.studies = val;
        },
        addStudy(state, val) {
            state.studies.push(val || {});
        },
        payOrder(state, val = false) {
            state.paid = true;
        },
        setReadOnly(state, val) {
            state.order_new = val;
        },
        applyDiscount(state, val = {}) {
            //console.log('aplicando descuentos: ', state.studies);
            state.discountLab = val.desc_lab;
            state.discountGab = val.desc_gab;
            state.studies = state.studies.map(el => {
                //console.log(el.is_lab, el.no_promo, state.discountLab, el.is_gab, state.discountGab,el.price_show);
                if (el.is_lab && !el.no_promo && state.discountLab) {
                    el.discount = calculateDiscount(state.discountLab, el.price_show);
                }
                if (el.is_gab && !el.no_promo && state.discountGab) {
                    el.discount = calculateDiscount(state.discountGab, el.price_show);
                }
                return el;
            });
        },
        removeDiscount(state) {
            state.discountLab = 0;
            state.discountGab = 0;
            state.studies.forEach(el => {
                el.discount = 0;
            });
        },
        clearOrder(state, saved = {}) {
            //console.log('limpando store', saved.studies);
            state.studies = saved.studies ? state.studies : [];
            state.company = saved.company ? state.company : {};
            state.patient = {};
            state.medical = JSON.parse(localStorage.getItem('medical')) || medical;
            state.order_new = null;
            state.paid = false;
            state.discountLab = 0;
            state.discountGab = 0;
            state.payInCash = saved.company ? state.company.pay_in_cash : true;
            state.coPay = false;
            state.coPayCustomPercent = 0;
            state.diagnostics = [{}, {}]; // solo dos diagnosticos
            state.foreignWorkCheck = false;
            state.foreignWork = 0;
            state.foreignWorkName = '';
            state.withIva = true;
            state.patientSign = '';
        },
        setDiagnostic(state, {value, pos}) {
            const data = state.diagnostics.slice(0);
            data[pos] = value;
            state.diagnostics = data;
        },
        setDiagnostics(state, val) {
            const data = [{}, {}];
            if (val[0]) {
                data[0] = val[0]
            }
            if (val[1]) {
                data[1] = val[1]
            }
            state.diagnostics = data;
        },
        setSubBranchOffice(state, val) {
            state.subBranchOffice = val;
        },
        setCoPayCustomPercent(state, val) {
            if (!Number.isNaN(val) && val >= 0 && val <= 100) {
                state.coPayCustomPercent = val;
            } else {
                state.coPayCustomPercent = 0;
            }
        },
        setForeignWork(state, val) {
            state.foreignWork = val;
        },
        setForeignWorkCheck(state, val) {
            state.foreignWorkCheck = val;
        },
        setForeignWorkName(state, val) {
            state.foreignWorkName = val;
        },
        setWithIva(state, val) {
            state.withIva = val;
        },
        setMedicalCode(state, val) {
            state.medicalCode = val
        },
        setPatientSign(state, val) {
            state.patientSign = val;
        }
    },
    actions: {
        setPatient(context, val) {
            context.commit('setPatient', val);
        },
        setCopay(context, val) {
            context.commit('setCopay', val);
            //context.commit('setPayInCash', val);
        },
        setLocalNumber(context, val) {
            context.commit('setLocalNumber', val);
        },
        removePatient(context) {
            context.commit('setPatient', {});
        },
        setCompany(context, val = {}) {
            context.commit('setCompany', val);
            let code = '';
            if (context.state.medical && val) {
                if (context.state.medical.medical_companies) {
                    const md = context.state.medical.medical_companies.find(item => {
                        if (item.company && val.company) {
                            return item.company.code === val.company.company
                        }
                        return false;
                    })
                    if (md) {
                        code = md.code;
                    }
                }
            }
            context.commit('setMedicalCode', code);
        },
        removeDiscount(context) {
            context.commit('removeDiscount');
        },
        setMedical(context, val) {
            context.commit('setMedical', val);
            let code = '';
            if (val && context.state.company) {
                if (val.medical_companies) {
                    const md = val.medical_companies.find(item => {
                        if (item.company) {
                            return item.company.code === context.state.company.company
                        }
                        return false;
                    })
                    if (md) {
                        code = md.code;
                    }
                }
            }
            context.commit('setMedicalCode', code);
        },
        setDefaultMedical(context) {
            context.commit('setDefaultMedical');
        },
        setStudies(context, val) {
            context.commit('setStudies', val);
            context.commit('applyDiscount', {
                desc_lab: context.state.discountLab,
                desc_gab: context.state.discountGab,
            });
        },
        addStudy(context, val) {
            context.commit('addStudy', val);
        },
        applyDiscount(context, val) {
            context.commit('applyDiscount', val);
        },
        payOrder(context, val) {
            context.commit('payOrder', val)
        },
        setReadOnly(context, val) {
            context.commit('setReadOnly', val);
        },
        clearOrder(context, saved) {
            context.commit('clearOrder', saved)
        },
        setDiagnostic(context, val) {
            context.commit('setDiagnostic', val);
        },
        setDiagnostics(context, val) {
            context.commit('setDiagnostics', val);
        },
        setSubBranchOffice(context, val) {
            context.commit('setSubBranchOffice', val);
        },
        setCoPayCustomPercent(context, val) {
            context.commit('setCoPayCustomPercent', val);
        },
        setForeignWork(context, val) {
            context.commit('setForeignWork', val);
        },
        setForeignWorkCheck(context, val) {
            context.commit('setForeignWorkCheck', val);
        },
        setForeignWorkName(context, val) {
            context.commit('setForeignWorkName', val);
        },
        setWithIva(context, val) {
            context.commit('setWithIva', val);
        },
        updatePrices(context, studies) {
            let newStudies = context.state.studies.slice();
            //primero fijamos los precios del backend por company y policies
            //  console.log(studies);
            newStudies = newStudies.map((item, pos) => {
                const obj = studies.find(item_backend => {
                    return item.code === item_backend.code;
                });
                if (obj) {
                    item = Object.assign({}, item, obj);
                }
                return item;
            });
            // console.log(newStudies);
            context.commit('setStudies', newStudies);
        },
        setMedicalCode(context, val) {
            context.commit('setMedicalCode', val);
        },
        setPatientSign(context, val) {
            context.commit('setPatientSign', val);
        }
    },
    getters: {
        hasPatient: state => {
            return !!state.patient.code;
        },
        hasCompany: ({company = {}}) => {
            return !!company.company;
        },
        hasMedical: state => {
            return !!state.medical.code;
        },
        subtotal: (state, getters) => {
            return getters.totalStudies - getters.totalDiscount;
        },
        tax: (state, getters) => {
            if (state.withIva) {
                return (Number(getters.subtotal) * 16) / 100;
            }
            return 0;
        },
        total: (state, getters) => {
            return getters.tax + getters.subtotal;
        },
        totalLab: state => {
            return state.studies.reduce((acc, el) => {
                if (el.is_lab) {
                    return Number(el.price_show || 0) + Number(acc)
                }
                return acc;
            }, 0);
        },
        totalDiscountGab: (state, getters) => {
            return Number(state.studies.reduce((acc, el) => {
                if (el.is_gab && el.discount > 0) {
                    return Number(el.discount) + Number(acc)
                }
                return acc;
            }, 0).toFixed(2));
        },
        totalStudies: state => {
            return Number(state.studies.reduce((acc, el) => {
                if (state.payInCash || state.coPay) {
                    return Number(el.price_show || 0) + Number(acc)
                } else {
                    return acc;
                }
            }, 0));
        },
        percentCopay: (state) => {
            return state.company.copay_percent || 0;
        },
        discount: (state, getters) => {
            let result = 0;
            switch (state.company.copay) {
                case '+':
                    result = Number(getters.totalStudies) - Number(state.company.copay_amount / 1.16).toFixed(2);
                    break;
                case '%':
                    let porcent = state.company.copay_percent;
                    if (state.company.copay_by_sex) {
                        porcent = (state.patient.gender || 'male') !== 'male' ? state.company.copay_percent_alt : porcent;
                    }
                    result = Number(Number(getters.totalStudies * (porcent / 100)).toFixed(2))
                    break;
                case '?':
                    result = Number(Number(getters.totalStudies * (Number(state.coPayCustomPercent).toFixed(2) / 100)).toFixed(2));
                    break
            }
            return Number(Number(result).toFixed(2));
        },
        totalDiscountLab: state => {
            return Number(state.studies.reduce((acc, el) => {
                if (el.is_lab && el.discount > 0) {
                    return Number(el.discount) + Number(acc)
                }
                return acc;
            }, 0).toFixed(2));
        },
        totalDiscount: (state, getters) => {
            if (state.coPay) {
                return getters.discount;
            } else {
                return getters.totalDiscountGab + getters.totalDiscountLab
            }
        },
        totalGab: state => {
            return state.studies.reduce((acc, el) => {
                if (el.is_gab) {
                    return Number(el.price_show) + Number(acc)
                }
                return acc;
            }, 0);
        },
        hasStudies: state => {
            return !!state.studies.length;
        },
        hasPayments: state => {
            return !!state.orderPayments.payments.length;
        },
        canPayOrder: (state, getters) => {
            if (!state.coPay && (!getters.hasStudies || !state.payInCash)) {
                return false;
            }

            if (getters.readOnly && state.payInCash) {
                return true;
            }

            if (getters.readOnly && state.coPay) {
                return true;
            }

            let studiesForPay = state.studies.filter(el => el.price_show !== 0);
            return (!getters.hasCompany || !!studiesForPay.length || getters.total > 0) && !getters.readOnly;
        },
        canApplyDiscount: (state, getters) => {
            if (getters.hasCompany) {
                return false;
            }
            return getters.canPayOrder && !state.coPay && !getters.readOnly;
        },
        canApplyPromotion: (state, getters) => {
            if (getters.hasCompany) {
                return false;
            }
            return getters.canPayOrder && !state.coPay && !getters.readOnly;
        },
        canCreateOrder: (state, getters) => {
            let payOrder = getters.canPayOrder ? state.paid : true;

            let allowLapifan = true;
            const patient = state.patient;
            const patientLapifan = patient && patient.lapifan_active && patient.lapifan_active.status;
            const existsLapifanStudy = state.studies.some(item => item.code === 'LAPIFAN')
            
            if((state.company.only_with_lapifan && !patientLapifan && !existsLapifanStudy)){
                allowLapifan = false;
            }

            return payOrder &&
                getters.hasStudies &&
                getters.hasPatient &&
                !getters.readOnly &&
                allowLapifan &&
                (!!!state.company.company || (state.company && state.company.can_sell));
        },
        hasOrderNew: state => {
            return state.order_new !== null;
        },
        canPrintOrder: (state, getters) => {
            return getters.hasOrderNew;
        },
        readOnly: state => {
            return state.order_new !== null;
        },
        isCancel: (state, getters) => {
            return state.order_new !== null && state.is_cancel;
        },
        priceList(state, getters, rootState, rootGetters) {
            if (getters.hasCompany) {
                if (state.payInCash) {
                    if (state.agreement) {
                        return state.company.price_list_pay_in_cash;
                    } else if (state.company.pay_in_cash_type !== 'all') {
                        return state.company.price_list;
                        //return state.company.price_list_pay_in_cash;
                    }
                }
                return state.company.price_list;
            }
            return rootGetters.authPriceList;
        },
        hasDataVaccineCovid: (state, getters) => {
            return state.studies.some((item) => {
                return item.require_data_vaccine_covid
            })
        }
    }
}
